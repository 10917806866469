<template>
  <div>
    <div class="w-50 mx-auto py-4 text-center">
      <h2 class="mb-2">Login</h2>
      <b-form-group class="mb-2 text-left" label="Password">
        <b-form-input v-model="password" :state="state" />
        <b-form-invalid-feedback> Invalid Password </b-form-invalid-feedback>
      </b-form-group>
      <b-button @click="loginUser">Log In</b-button>
    </div>
  </div>
</template>

<script>
import expensesService from "../expenses.service";
import useExpensesAuth from "../useLogin";

const { isLogged } = useExpensesAuth();

export default {
  data: () => ({
    password: "",
    state: null,
  }),
  methods: {
    async loginUser() {
      let isAllowed = await expensesService.getPermissions(
        this.password,
        this.moduleId
      );
      if (isAllowed) {
        isLogged.value = true;
        this.$router.push({ name: "expenses-index-bussiness" });
        return;
      }
      this.state = false;
      this.password = "";
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
